module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.healthcareplans.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-5M6C38","brandTokens":["HCP","ZING"],"mapiBrandToken":"MED","siteName":"healthcareplans-new","alternateName":"healthcareplans.com","siteURL":"https://www.healthcareplans.com","defaultTitleTemplate":"","defaultPhone":"8552009406","phoneSymbol":"-","defaultPromoCode":"157979","smartyStreetsWebsiteKey":"","addressQualificationCode":"","vwoProjectID":"894940","cobra":{"sitePath":"healthcareplans.com","buttonLink":"https://enroll.clearlinkinsurance.com?site=hcp","buttonText":"View Plans","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"healthcareplans-new","short_name":"healthcareplans-new","start_url":"/","background_color":"#A0105C","theme_color":"#A0105C","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c2b087c9279d5b26f9cb24c6fa313f1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
